require('./bootstrap');


$(".navbar-toggler").click(function () {
  var nav = $(".navbar-slide");
  nav.toggleClass("is-open");
  $(".icon-bar-1").addClass("animate");
  $(".icon-bar-2").addClass("animate");
  $(".icon-bar-3").addClass("animate");
});

$('.nav-link-mobile').click(function () {
  var nav = $(".navbar-slide");
  nav.toggleClass("is-open");
});


  $('a.smooth-scroll').click(function() {
    console.log('a');
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      console.log('b');
      if (target.length) {
        $('html, body').animate({
          scrollTop: target.offset().top 
        }, 100);
        console.log('c');
        return false;
      }
    }
  });
